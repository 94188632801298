import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_patent_managet0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_manage/t/search', data: data, method: 'POST' });
const http_patent_managet5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_manage/t/edit', data: data, method: 'POST' });
const http_patent_managetget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_manage/t/get_detail', data: data, method: 'POST' });
const http_patent_managetget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_manage/t/get_edit', data: data, method: 'POST' });

const http_patent_managetgetLeaderList0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_manage/t/get_leader_list', data: data, method: 'POST' });

const http_get_evaluation_criterion:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_manage/t/get_evaluation_criterion', data: data, method: 'POST' });

export default {
  http_patent_managet0,
  http_patent_managet5,
  http_patent_managetgetLeaderList0,
  http_patent_managetget4,
  http_patent_managetget5,
  http_get_evaluation_criterion
}
