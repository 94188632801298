import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取表格
const http_supplementary_materialst0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/supplementary_materials/t/search', data: data, method: 'POST' });

// 上传补充材料
const http_supplementary_materialst5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/supplementary_materials/t/edit', data: data, method: 'POST' });
const http_supplementary_materialstget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/supplementary_materials/t/get_edit', data: data, method: 'POST' });

export default {
  http_supplementary_materialst0,
  http_supplementary_materialst5,
  http_supplementary_materialstget5
}
