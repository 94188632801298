import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/test/test.vue'),
  //   meta: {
  //     title: '测试'
  //   }
  // },
  // {
  //   path: '/test2',
  //   name: 'test2',
  //   component: () => import('@/views/test/test2.vue'),
  //   meta: {
  //     title: '测试2'
  //   }
  // },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/base/login/login.vue'),
    meta: {
      title: '广东专利评估与转化'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/base/user-agreement/user-agreement.vue'),
    meta: {
      title: '用户隐私协议',
      requireAuth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/base/register/register.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/forget_pass',
    name: 'forget_pass',
    component: () => import('@/views/base/forget_pass/forget_pass.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/user-agreement',
    name: 'user-agreement',
    component: () => import('@/views/base/user-agreement/user-agreement.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/forget-pass',
    name: 'forget-pass',
    component: () => import('@/views/base/forget-pass/forget-pass.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
    redirect: {
      name: 'workbench'
    },
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: 'workbench',
        name: 'workbench',
        component: () => import('@/views/base/workbench/workbench.vue'),
        meta: {
          requireAuth: true,
          title: ''
        }
      },
      {
        path: 'person',
        name: 'person',
        component: () => import('@/views/base/person/person.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'message-center',
        name: 'message-center',
        component: () => import('@/views/base/message-center/message-center.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'user-manage',
        name: 'user-manage',
        component: () => import('@/views/base/user-manage/user-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'dept-manage',
        name: 'dept-manage',
        component: () => import('@/views/base/dept-manage/dept-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'work-unit',
        name: 'work-unit',
        component: () => import('@/views/base/work-unit/index.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'quota',
        name: 'quota',
        component: () => import('@/views/base/quota/quota.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'role-manage',
        name: 'role-manage',
        component: () => import('@/views/base/role-manage/role-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'industry-manage',
        name: 'industry-manage',
        component: () => import('@/views/base/industry-manage/industry-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'achievement-manage',
        name: 'achievement-manage',
        component: () => import('@/views/assess/achievement-manage/achievement-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'outcome-change',
        name: 'outcome-change',
        component: () => import('@/views/change/outcome-change/outcome-change.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'change-manage',
        name: 'change-manage',
        component: () => import('@/views/change/change-manage/change-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'change-audit',
        name: 'change-audit',
        component: () => import('@/views/change/change-audit/change-audit.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'tech-input',
        name: 'tech-input',
        component: () => import('@/views/assess/tech-input/tech-input.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'pais',
        name: 'pais',
        component: () => import('@/views/base/pais/pais.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'proofreading',
        name: 'proofreading',
        component: () => import('@/views/base/proofreading/proofreading.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'certification-audit',
        name: 'certification-audit',
        component: () => import('@/views/base/certification-audit/certification-audit.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'assessor-audit',
        name: 'assessor-audit',
        component: () => import('@/views/base/assessor-audit/assessor-audit.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'my-patent',
        name: 'my-patent',
        component: () => import('@/views/patent/my-patent/my-patent.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      // {
      //   path: 'add-patent',
      //   name: 'add-patent',
      //   component: () => import('@/views/patent/add-patent/add-patent.vue'),
      //   meta: {
      //     title: '添加专利',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: 'patent-detail',
      //   name: 'patent-detail',
      //   component: () => import('@/views/patent/patent-detail/patent-detail.vue'),
      //   meta: {
      //     title: '专利详情',
      //     requireAuth: true
      //   }
      // },
      {
        path: 'patent-result',
        name: 'patent-result',
        component: () => import('@/views/patent/patent-result/patent-result.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'patent-manage',
        name: 'patent-manage',
        component: () => import('@/views/patent/patent-manage/patent-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'patent-task',
        name: 'patent-task',
        component: () => import('@/views/patent/patent-task/patent-task.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'evaluate-task',
        name: 'evaluate-task',
        component: () => import('@/views/patent/evaluate-task/evaluate-task.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'patent-evaluate',
        name: 'patent-evaluate',
        component: () => import('@/views/patent/patent-evaluate/patent-evaluate.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'my-patent-level',
        name: 'my-patent-level',
        component: () => import('@/views/patent-level/my-patent/my-patent.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'apply-confirm',
        name: 'apply-confirm',
        component: () => import('@/views/patent/apply-confirm/apply-confirm.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'apply-confirm',
        name: 'apply-confirm',
        component: () => import('@/views/patent/apply-confirm/apply-confirm.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'apply-confirm',
        name: 'apply-confirm',
        component: () => import('@/views/patent/apply-confirm/apply-confirm.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'confirmed',
        name: 'confirmed',
        component: () => import('@/views/patent/confirmed/confirmed.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'report-examine',
        name: 'report-examine',
        component: () => import('@/views/patent/report-examine/report-examine.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      // {
      //   path: 'add-patent-level',
      //   name: 'add-patent-level',
      //   component: () => import('@/views/patent-level/add-patent/add-patent.vue'),
      //   meta: {
      //     title: '添加专利',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: 'patent-detail-level',
      //   name: 'patent-detail-level',
      //   component: () => import('@/views/patent-level/patent-detail/patent-detail.vue'),
      //   meta: {
      //     title: '专利详情',
      //     requireAuth: true
      //   }
      // },
      {
        path: 'patent-result-level',
        name: 'patent-result-level',
        component: () => import('@/views/patent-level/patent-result/patent-result.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'patent-manage-level',
        name: 'patent-manage-level',
        component: () => import('@/views/patent-level/patent-manage/patent-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'patent-task-level',
        name: 'patent-task-level',
        component: () => import('@/views/patent-level/patent-task/patent-task.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'evaluate-task-level',
        name: 'evaluate-task-level',
        component: () => import('@/views/patent-level/evaluate-task/evaluate-task.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'patent-evaluate-level',
        name: 'patent-evaluate-level',
        component: () => import('@/views/patent-level/patent-evaluate/patent-evaluate.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'apply-confirm-level',
        name: 'apply-confirm-level',
        component: () => import('@/views/patent-level/apply-confirm/apply-confirm.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'confirmed-level',
        name: 'confirmed-level',
        component: () => import('@/views/patent-level/confirmed/confirmed.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'report-examine-level',
        name: 'report-examine-level',
        component: () => import('@/views/patent-level/report-examine/report-examine.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'outcome-application',
        name: 'outcome-application',
        component: () => import('@/views/assess/outcome-application/outcome-application.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'outcome-report',
        name: 'outcome-report',
        component: () => import('@/views/assess/outcome-report/outcome-report.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'expert-result',
        name: 'expert-result',
        component: () => import('@/views/assess/expert-result/expert-result.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'apply-audit',
        name: 'apply-audit',
        component: () => import('@/views/assess/apply-audit/apply-audit.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'supplementary-materials',
        name: 'supplementary-materials',
        component: () => import('@/views/assess/supplementary-materials/supplementary-materials.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'open-invitation',
        name: 'open-invitation',
        component: () => import('@/views/assess/open-invitation/open-invitation.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'apply-history',
        name: 'apply-history',
        component: () => import('@/views/assess/apply-history/apply-history.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'evaluate-assignment',
        name: 'evaluate-assignment',
        component: () => import('@/views/assess/evaluate-assignment/evaluate-assignment.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'evaluate-detail',
        name: 'evaluate-detail',
        component: () => import('@/views/assess/evaluate-detail/evaluate-detail.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'business-management',
        name: 'business-management',
        component: () => import('@/views/assess/business-management/business-management.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'complaints-handling',
        name: 'complaints-handling',
        component: () => import('@/views/assess/complaints-handling/complaints-handling.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'apply-task',
        name: 'apply-task',
        component: () => import('@/views/assess/apply-task/apply-task.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'supplementary-audit',
        name: 'supplementary-audit',
        component: () => import('@/views/assess/supplementary-audit/supplementary-audit.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'write-report',
        name: 'write-report',
        component: () => import('@/views/assess/write-report/write-report.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'report-review',
        name: 'report-review',
        component: () => import('@/views/assess/report-review/report-review.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'add-empower',
        name: 'add-empower',
        component: () => import('@/views/empower/add-empower/add-empower.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'empower-detail',
        name: 'empower-detail',
        component: () => import('@/views/empower/empower-detail/empower-detail.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'result-empower',
        name: 'result-empower',
        component: () => import('@/views/empower/result-empower/result-empower.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'empower-manage',
        name: 'empower-manage',
        component: () => import('@/views/empower/empower-manage/empower-manage.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'empower-audit',
        name: 'empower-audit',
        component: () => import('@/views/empower/empower-audit/empower-audit.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'empower-step',
        name: 'empower-step',
        component: () => import('@/views/empower/empower-step/empower-step.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'change-step',
        name: 'change-step',
        component: () => import('@/views/change/change-step/change-step.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'change-dept-audit',
        name: 'change-dept-audit',
        component: () => import('@/views/change/dept-audit/index.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'change-dept1-audit',
        name: 'change-dept1-audit',
        component: () => import('@/views/change/dept1-audit/index.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'empower-dept-audit',
        name: 'empower-dept-audit',
        component: () => import('@/views/empower/dept-audit/index.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'empower-dept1-audit',
        name: 'empower-dept1-audit',
        component: () => import('@/views/empower/dept1-audit/index.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      },
      {
        path: 'operation-log',
        name: 'operation-log',
        component: () => import('@/views/safety/log/log.vue'),
        meta: {
          title: '',
          requireAuth: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('removeDialogName')
  // 判断是否已登录
  // console.log(to)
  to.meta.title = to.meta.title ? to.meta.title : '广东专利评估与转化'
  document.title = to.meta.title as string
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权权限
    if (store.state.token) {
      //
      next()
    } else {
      //
      next({ path: '/' }) //
    }
  } else {
    next()
  }
})

export default router
