import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取/搜索师生员工表格
const http_proofreadingtable0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/proofreading/table/search', data: data, method: 'POST' });

// 审核
const http_proofreadingtable5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/proofreading/table/edit', data: data, method: 'POST' });

const http_proofreadingtableget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/proofreading/table/get_detail', data: data, method: 'POST' });
const http_proofreadingtableget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/proofreading/table/get_edit', data: data, method: 'POST' });

export default {
  http_proofreadingtable0,
  http_proofreadingtable5,
  http_proofreadingtableget4,
  http_proofreadingtableget5
}
