import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_supplementary_auditt0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/supplementary_audit/t/search', data: data, method: 'POST' });
const http_supplementary_auditt5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/supplementary_audit/t/edit', data: data, method: 'POST' });
const http_supplementary_audittget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/supplementary_audit/t/get_detail', data: data, method: 'POST' });
const http_supplementary_audittget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/supplementary_audit/t/get_edit', data: data, method: 'POST' });

export default {
  http_supplementary_auditt0,
  http_supplementary_auditt5,
  http_supplementary_audittget4,
  http_supplementary_audittget5
}
