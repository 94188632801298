import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取角色列表
 * @param data
 * @returns
 */
const http_roletable10: Api = (data: any) => httpRequest.request({ url: 'api/v1/role/table1/search', data: data, method: 'POST' });

/**
 * 添加角色
 * @param data
 * @returns
 */
const http_roletable11: Api = (data: any): any => httpRequest.request({ url: 'api/v1/role/table1/add', data: data, method: 'POST' });

/**
 * 删除角色
 * @param data
 * @returns
 */
const http_roletable12: Api = (data: any): any => httpRequest.request({ url: 'api/v1/role/table1/del', data: data, method: 'POST' });

/**
 * 编辑角色
 * @param data
 * @returns
 */
const http_roletable15: Api = (data: any): any => httpRequest.request({ url: 'api/v1/role/table1/edit', data: data, method: 'POST' });

/**
 * 获取编辑角色弹窗数据
 * @param data
 * @returns
 */
const http_roletable1get5: Api = (data: any) => httpRequest.request({ url: 'api/v1/role/table1/get_edit', data: data, method: 'POST' });

/**
 * 编辑角色权限
 * @param data
 * @returns
 */
const http_roletable16: Api = (data: any): any => httpRequest.request({ url: 'api/v1/role/table1/edit1', data: data, method: 'POST' });

/**
 * 获取编辑角色权限数据
 * @param data
 * @returns
 */
const http_roletable1get6: Api = (data: any) => httpRequest.request({ url: 'api/v1/role/table1/get_edit1', data: data, method: 'POST' });

/**
 * 获取模块树列表
 * @param data
 * @returns
 */
const http_roletable1dataSource0: Api = (data: any) => httpRequest.request({ url: 'api/v1/module/getModulesAll', data: data, method: 'POST' })

/**
 * 获取部门列表
 * @param data
 * @returns
 */
const http_roletable1depts0: Api = (data: any) => httpRequest.request({ url: 'api/v1/dept/depts/search', data: data, method: 'POST' })

export default {
  http_roletable10,
  http_roletable11,
  http_roletable12,
  http_roletable15,
  http_roletable1get5,
  http_roletable16,
  http_roletable1get6,
  http_roletable1dataSource0,
  http_roletable1depts0
}
