
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import { Options } from 'vue-class-component'
@Options({
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    options(newValue) {
      this.optionsAnayse()
    }
  }
})
export default class Login extends SuperVue {
  created() {
    this.optionsAnayse();
  }
  options!:any;
  table:any[]=[];
  rows:string[] = [];
  optionsAnayse() {
    this.table = [];
    this.rows = [];
    this.table = this.options.bmk;
    for (const ok in this.options) {
      if (/obj[0-9]+_info/.test(ok)) {
        this.rows.push(this.options[ok])
      }
    }

  }
}
