import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_getMessageByToken: Api = (data: any): any => httpRequest.request({ url: 'api/v1/myMessage/getMessageByToken', data: data, method: 'POST' })
const http_getReadCount: Api = (data: any) => httpRequest.request({ url: 'api/v1/myMessage/getReadCount', data: data, method: 'POST' })
const http_setReader: Api = (data: any) => httpRequest.request({ url: 'api/v1/myMessage/setReader', data: data, method: 'POST' })

export default {
  http_getMessageByToken,
  http_getReadCount,
  http_setReader
}

