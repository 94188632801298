import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取申诉列表
 * @param data
 * @returns
 */
const http_complaintstable10: Api = (data: any): any => httpRequest.request({ url: 'api/v1/complaints/table1/search', data: data, method: 'POST' });

/**
 * 获取申诉详情
 * @param data
 * @returns
 */
const http_complaintstable1get4: Api = (data: any): any => httpRequest.request({ url: 'api/v1/complaints/table1/get_detail', data: data, method: 'POST' });

/**
 * 处理申诉
 * @param data
 * @returns
 */
const http_complaintstable15: Api = (data: any): any => httpRequest.request({ url: 'api/v1/complaints/table1/edit', data: data, method: 'POST' });

/**
 * 获取处理申诉弹窗数据
 * @param data
 * @returns
 */
const http_complaintstable1get5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/complaints/table1/get_edit', data: data, method: 'POST' });

export default {
  http_complaintstable10,
  http_complaintstable1get4,
  http_complaintstable15,
  http_complaintstable1get5
}
