
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'

@Options({
  components: {},
  watch: {
    options() {
      this.renderData()
    },
    type(newVal) {
      if (newVal === 0) {
        this.weight = []
        this.evaluate.weights.forEach((item) => this.weight.push(item.weight))
      }
      if (newVal === 1) this.weight = [40, 30, 10, 10, 10]
      if (newVal === 2) this.weight = [20, 20, 30, 20, 10]
      if (newVal === 3) this.weight = [20, 20, 30, 20, 10]
    }
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return []
      }
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: [Number, String],
      default: 1
    }
  }
})
export default class my_patent extends SuperVue {
  modelValue!: any[]
  options!: any[]
  type!: number | string
  updated() {
    this.renderData()
  }
  // 基础研究 [40,30,10,10,10]
  // 应用研究/技术开发与产业化 [20,20,30,20,10]
  weight = [40, 30, 10, 10, 10] as any

  evaluate = {
    weights: [] as any
  }

  scroSlect = (rule, value, callback) => {
    if (value) {
      if (value < 0) {
        callback(new Error('请填写完整'))
      } else {
        callback();
      }
    } else {
      callback(new Error('请填写完整'));
    }
  }

  t_data = {
    self: {
      score: '',
      description: ''
    }
  }
  rule_t_data = {
    'self.score': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      { validator: this.scroSlect, trigger: 'blur' }
    ]
  }

  radioChange(index, score) {
    console.log('7777777777', this.evaluate)
    this.evaluate.weights[index].score = score
    this.formItemChange()
  }
  formItemChange() {
    this.$nextTick(() => {
      this.$emit('update:modelValue', this.evaluate.weights)
      // console.log(this.evaluate.weights)
    })
  }
  renderData() {
    this.evaluate.weights = []
    this.evaluate.weights = JSON.parse(JSON.stringify(this.options))
    this.evaluate.weights.forEach((item) => {
      item.score = null
      item.description = ''
      item.file = []
    })

    this.modelValue.forEach((item) => {
      const index = this.evaluate.weights.findIndex(
        (itemA) => itemA.id === item.id
      )
      if (index > -1) {
        this.evaluate.weights[index].score = item.score
        this.evaluate.weights[index].description = item.description
        this.evaluate.weights[index].file = item.file ? item.file : []
      }
    })

    const temp = this.evaluate.weights
    this.evaluate.weights = []
    this.evaluate.weights = JSON.parse(JSON.stringify(temp))
    if (this.type === 0) {
      this.weight = []
      this.evaluate.weights.forEach((item) => this.weight.push(item.weight))
    }
  }
}
