import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 查询部门列表
 * @param data
 * @returns
 */
const http_deptdepts0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/search', data: data, method: 'POST' });//

/**
 * 添加部门信息
 * @param data
 * @returns
 */
const http_deptdepts1: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/add', data: data, method: 'POST' });

/**
 * 删除部门
 * @param data
 * @returns
 */
const http_deptdepts2: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/del', data: data, method: 'POST' });

/**
 * 修改部门信息
 * @param data
 * @returns
 */
const http_deptdepts5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/edit', data: data, method: 'POST' });

/**
 * 获取修改部门弹窗信息
 * @param data
 * @returns
 */
const http_deptdeptsget5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/get_edit', data: data, method: 'POST' });

/**
 * 新增子部门
 * @param data
 * @returns
 */
const http_deptdepts6: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/edit1', data: data, method: 'POST' });

/**
 * 获取新增子部门弹窗
 * @param data
 * @returns
 */
const http_deptdeptsget6: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/get_edit1', data: data, method: 'POST' });

/**
 * 获取部门下的用户,供dept-user-tree组件使用
 */
const http_deptGetUserList: Api = (data: any) => httpRequest.request({ url: 'api/v1/dept/depts/getUserList', data: data, method: 'POST' });//

// 获取部门树列表
const http_deptGetDeptTree: Api = (data:any) => httpRequest.request({ url: 'api/v1/dept/depts/getDeptTree', data: data, method: 'POST' })

export default {
  http_deptdepts0,
  http_deptdepts1,
  http_deptdepts2,
  http_deptdepts5,
  http_deptdeptsget5,
  http_deptdepts6,
  http_deptdeptsget6,
  http_deptGetUserList,
  http_deptGetDeptTree
}
