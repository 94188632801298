import { httpRequest } from '@/plugins'
import '@/api/interface'

// 删除文件
const http_fileDelete: Api = (data: any): any => httpRequest.request({ url: 'api/v1/file/fileDelete', data: data, method: 'POST' })
// 发送邮箱验证码
const http_sendEmailCode: Api = (data: any): any => httpRequest.request({ url: 'api/v1/mail/sendEmailCode', data: data, method: 'POST' })
// 发送手机验证码
const http_sendCode: Api = (data: any) => httpRequest.request({ url: 'api/v1/common/sendCode', data: data, method: 'POST' })
// 校验手机验证码
const http_checkCode: Api = (data: any) => httpRequest.request({ url: 'api/v1/common/checkCode', data: data, method: 'POST' })
// 文件上传
const http_fileUpload: Api = (data: any) => httpRequest.request({ url: 'api/v1/file/fileUpload', data: data, method: 'POST' })

export default {
  http_fileDelete,
  http_sendEmailCode,
  http_sendCode,
  http_checkCode,
  http_fileUpload
}
