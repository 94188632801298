import store from '@/store';
class Utils {

  /**
   * 获取cookie
   * @param key
   * @returns
   */
  static getCookie(key: string): string {
    if (document.cookie.length > 0) {
      let start = document.cookie.indexOf(key + '=');
      if (start !== -1) {
        start += key.length + 1;
        let end = document.cookie.indexOf(';', start);
        if (end === -1) end = document.cookie.length;
        return atob(decodeURI(document.cookie.substring(start, end)));
      }
    }
    return ''
  }

  /**
   * 删除cookie
   * @param key
   */
  static delCookie(key: string): void {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);
    const cval = this.getCookie(key);
    if (cval != null) {
      document.cookie = key + '=' + cval + ';expires=' + exp.toUTCString();
    }
  }

  /**
   * 设置cookie
   * @param key
   * @param value
   * @param expireMsec
   */
  static setCookie(key: string, value: string, expireMsec: number): void {
    const exdate = new Date();
    exdate.setTime(exdate.getTime() + expireMsec);
    document.cookie = key + '=' + btoa(encodeURI(value)) + ((expireMsec == null) ? '' : ';expires=' + exdate.toUTCString());
  }

  /**
   * 时间格式化
   * @param date
   * @param format
   * @returns
   */
  static dataFormat(date, format = 'yyyy-mm-dd') {
    const time = new Date(date)
    const yyyy = time.getFullYear().toString()
    const mm = (time.getMonth() + 1).toString().padStart(2, '0')
    const dd = time.getDate().toString().padStart(2, '0')
    return format.replace('yyyy', yyyy).replace('mm', mm).replace('dd', dd)
  }

  /**
   * 获取两个时间的间隔
   * @param first
   * @param last
   * @returns
   */
  static diffTime(first: string, last: string): string {
    const timestamp = Math.abs(new Date(first).getTime() - new Date(last).getTime());
    const m = Math.ceil(timestamp / 1000 / 60 % 60)
    const h = Math.floor(timestamp / 1000 / 60 / 60)
    return `${ h }时${ m.toString().padStart(2, '0') }分`;
  }

  /**
   * 时间格式化
   * @param time
   * @returns
   */
  static formatTime(time: string): string {
    const day = new Date(time)
    const m = day.getMinutes()
    const h = day.getHours()
    const D = day.getDate()
    const M = day.getMonth() + 1;
    return `${ M }-${ D } ${ h }:${ m }`;
  }

  /**
   * 控制按钮是否展示,权限后台获取
   * @param name 按钮权限名
   * @returns bool,是否可以打开
   */
  static showBtn(name: string) {
    const index = store.state.access.findIndex((item: any) => {
      return item.name === name;
    })
    return index !== -1;
    // return true;
  }

  /**
   * 数组根据p_id转树形结构
   * @param list 待转数组
   * @param id 数组id
   * @param pid 数组转树的父id
   * @param child 期望输出的子项字段名
   * @returns
   */
  static list2tree(list, id = 'id', pid = 'parent_id', child = 'child'): any {
    // console.log(list)
    const list1 = JSON.parse(JSON.stringify(list))
    const treeMap = {};
    list1.forEach((item: any) => { // 列表转对象
      treeMap[item[id]] = item;
    });
    // console.log(treeMap)
    const tree: any = [];
    list1.forEach(item => {
      if (treeMap[item[pid]]) { // 在树对象中找到数据
        if (!treeMap[item[pid]][child]) treeMap[item[pid]][child] = [];
        treeMap[item[pid]][child].push(item);
      } else {
        tree.push(item);
      }
    });
    tree.sort((a, b) => a.sort - b.sort)
    return tree;
  }

  /**
   * 数组根据p_id转树形结构,仅供dept-user-tree组件使用,特殊处理
   * @param list 待转数组
   * @param id 数组id
   * @param pid 数组转树的父id
   * @param child 期望输出的子项字段名
   * @returns
   */
  static list2tree2(list, id = 'id', pid = 'parent_id', child = 'child'): any {
    const treeMap = {};
    list.forEach((item: any, idx: number) => {
      treeMap[item[id]] = item;
      treeMap[item[id]][child] = [
        {
          label: '用户',
          [child]: [],
          id: -1 * 2 * idx - 1
        }

      ]
    });
    const tree: any = [];
    list.forEach((item, idx) => {
      if (treeMap[item[pid]]) {
        if (treeMap[item[pid]][child].length === 1) {
          treeMap[item[pid]][child].push({
            label: '子部门',
            [child]: [],
            id: -1 * 2 * idx
          })
        }
        treeMap[item[pid]][child][1][child].push(item);
      } else {
        tree.push(item);
      }
    });
    return tree;
  }

  /**
   * 判断一个数据是否是对象或数组
   * @param val
   * @returns
   */
  static isObjAndArr(val) {
    return (
      Object.prototype.toString.call(val) === '[object Array]'
      || Object.prototype.toString.call(val) === '[object Object]'
    );
  }

  /**
   * 判断是否为json
   * @param str
   * @returns
   */
  static isJSON(str) {
    if (str === null) return false;
    try {
      if (typeof JSON.parse(str) === 'object') {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  /**
   * 判断两个对象是否相等，这两个对象的值只能是数字或字符串
   * @param obj1
   * @param obj2
   * @returns
   */
  static objEqual(obj1, obj2) {
    const keysArr1 = Object.keys(obj1);
    const keysArr2 = Object.keys(obj2);
    if (keysArr1.length !== keysArr2.length) return false;
    else if (keysArr1.length === 0 && keysArr2.length === 0) return true;
    /* eslint-disable-next-line */ else {
      return !keysArr1.some(key => obj1[key] != obj2[key]);
    }
  }

  static getPatentLevel(score) {
    if (score >= 90) return '特级'
    else if (score >= 80 && score < 90) return 'I级'
    else if (score >= 70 && score < 80) return 'II级'
    else if (score >= 60 && score < 70) return 'III级'
    else if (score >= 40 && score < 60) return 'IV级'
    else return 'V级'
  }

  /**
   * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
   * 如果没有传入key这个参数，则判断obj对象是否有键值对
   * @param obj
   * @param key
   * @returns
   */
  static hasKey(obj, key) {
    if (key) return key in obj;
    else {
      const keysArr = Object.keys(obj);
      return keysArr.length;
    }
  }

  /**
   * 打印数据
   * @param key
   * @param value
   */
  static log(key: string, value: any) {
    console.log(key, JSON.parse(JSON.stringify(value)));
  }

  /**
   * 获取身份证信息
   * @param idcard 身份证号
   * @param type 类型: 1.获取出生日期, 2.获取性别, 3.获取年龄
   */
  static getIdCardInfo(idcard: string, type: number) {
    if (type === 1) {
      // 获取出生日期
      const birthday = idcard.substring(6, 10) + '-' + idcard.substring(10, 12) + '-' + idcard.substring(12, 14)
      return birthday
    }
    if (type === 2) {
      // 获取性别
      if (parseInt(idcard.substr(16, 1)) % 2 === 1) {
        return '男'
      } else {
        return '女'
      }
    }
    if (type === 3) {
      // 获取年龄
      const ageDate = new Date()
      const month = ageDate.getMonth() + 1
      const day = ageDate.getDate()
      let age = ageDate.getFullYear() - parseInt(idcard.substring(6, 10)) - 1
      if (parseInt(idcard.substring(10, 12)) < month || parseInt(idcard.substring(10, 12)) === month && parseInt(idcard.substring(12, 14)) <= day) {
        age++
      }
      if (age <= 0) {
        age = 1
      }
      return age
    }
  }

  /**
   * 密码复杂度校验, 必须位字母 + 数字 + 特殊字符, 8到12位
   * @param pass
   * @returns
   */
  static checkPass(pass: string) {
    const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,12}');
    if (!pwdRegex.test(pass)) {
      return false
    }
    return true
  }
}

export default Utils;
