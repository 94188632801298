import { httpRequest } from '@/plugins'
import '@/api/interface'
const http_apply_taskt0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/search', data: data, method: 'POST' });
const http_apply_taskt5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/edit', data: data, method: 'POST' });
const http_apply_taskt6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/edit1', data: data, method: 'POST' });
const http_apply_taskt7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/edit2', data: data, method: 'POST' });
const http_apply_taskt8:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/edit3', data: data, method: 'POST' });
const http_apply_taskt9:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/edit4', data: data, method: 'POST' });
const http_apply_taskt10:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/edit5', data: data, method: 'POST' });
const http_apply_taskt11:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/edit6', data: data, method: 'POST' });
const http_apply_tasktget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/get_edit', data: data, method: 'POST' });
const http_apply_tasktget6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/get_edit1', data: data, method: 'POST' });
const http_apply_tasktget7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/get_edit2', data: data, method: 'POST' });
const http_apply_tasktget8:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/get_edit3', data: data, method: 'POST' });
const http_apply_tasktget9:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/get_edit4', data: data, method: 'POST' });
const http_apply_tasktget10:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/get_edit5', data: data, method: 'POST' });
const http_apply_tasktget11:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/t/get_edit6', data: data, method: 'POST' });
// todo
const http_apply_tasktgetWeightList0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/expert_apply/auditApply', data: data, method: 'POST' });
// todo
const http_apply_tasktexpertList0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/expert_apply/auditApply', data: data, method: 'POST' });
// todo
const http_apply_tasktgetUserList0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_task/getUserList', data: data, method: 'POST' });
// todo
const http_apply_tasktgetUserList20:Api = (data: any): any => httpRequest.request({ url: 'api/v1/user/getUserList', data: data, method: 'POST' });

export default {
  http_apply_taskt0,
  http_apply_taskt5,
  http_apply_taskt6,
  http_apply_taskt7,
  http_apply_taskt8,
  http_apply_taskt9,
  http_apply_taskt10,
  http_apply_taskt11,
  http_apply_tasktget5,
  http_apply_tasktget6,
  http_apply_tasktget7,
  http_apply_tasktget8,
  http_apply_tasktget9,
  http_apply_tasktget10,
  http_apply_tasktget11,
  http_apply_tasktexpertList0,
  http_apply_tasktgetWeightList0,
  http_apply_tasktgetUserList0,
  http_apply_tasktgetUserList20
}
