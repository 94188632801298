import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_assessor_audittable0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/assessor_audit/table/search', data: data, method: 'POST' });
const http_assessor_audittable5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/assessor_audit/table/edit', data: data, method: 'POST' });
const http_assessor_audittableget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/assessor_audit/table/get_detail', data: data, method: 'POST' });
const http_assessor_audittableget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/assessor_audit/table/get_edit', data: data, method: 'POST' });

export default {
  http_assessor_audittable0,
  http_assessor_audittable5,
  http_assessor_audittableget4,
  http_assessor_audittableget5
}
