import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_outcome_changet0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/search', data: data, method: 'POST' });
const http_outcome_changet1:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/add', data: data, method: 'POST' });
const http_outcome_changet2:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/del', data: data, method: 'POST' });
const http_outcome_changet5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/edit', data: data, method: 'POST' });
const http_outcome_changet6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/edit1', data: data, method: 'POST' });

const http_outcome_changetget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/get_detail', data: data, method: 'POST' });
const http_outcome_changetget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/get_edit', data: data, method: 'POST' });
const http_outcome_changetget6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_change/t/get_edit1', data: data, method: 'POST' });

// 获取赋权审批流程
const http_outcome_change_get_judge_options:Api = data => httpRequest.request({ url: 'api/v1/outcome_change/t/get_judge_options', data: data, method: 'POST' })

export default {
  http_outcome_changet0,
  http_outcome_changet1,
  http_outcome_changet2,
  http_outcome_changet5,
  http_outcome_changet6,
  http_outcome_changetget4,
  http_outcome_changetget5,
  http_outcome_changetget6,
  http_outcome_change_get_judge_options
}
