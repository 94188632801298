import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取表格数据
const http_result_empowertable10:Api = (data: any): any => httpRequest.request({ url: 'api/v1/result_empower/table1/search', data: data, method: 'POST' });
// 添加表格数据
const http_result_empowertable11:Api = (data: any): any => httpRequest.request({ url: 'api/v1/result_empower/table1/add', data: data, method: 'POST' });
// 编辑表格数据
const http_result_empowertable15:Api = (data: any): any => httpRequest.request({ url: 'api/v1/result_empower/table1/edit', data: data, method: 'POST' });
const http_result_empowertable16:Api = (data: any): any => httpRequest.request({ url: 'api/v1/result_empower/table1/edit1', data: data, method: 'POST' });
const http_result_empowertable1get4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/result_empower/table1/get_detail', data: data, method: 'POST' });
const http_result_empowertable1get5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/result_empower/table1/get_edit', data: data, method: 'POST' });
const http_result_empowertable1get6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/result_empower/table1/get_edit1', data: data, method: 'POST' });

// 获取赋权审批流程
const http_result_empower_get_judge_options:Api = data => httpRequest.request({ url: 'api/v1/result_empower/table1/get_judge_options', data: data, method: 'POST' })

export default {
  http_result_empowertable10,
  http_result_empowertable11,
  http_result_empowertable15,
  http_result_empowertable16,
  http_result_empowertable1get4,
  http_result_empowertable1get5,
  http_result_empowertable1get6,
  http_result_empower_get_judge_options
}
