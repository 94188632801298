import { httpRequest } from '@/plugins'
import '@/api/interface'
const address = require('@/plugins/provinces.json')

const http_patent_taskt0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/search', data: data, method: 'POST' });

// 专利审核
const http_patent_taskt5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/edit', data: data, method: 'POST' });
// 指派专家
const http_patent_taskt6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/edit1', data: data, method: 'POST' });
// 点评,采纳专家
const http_patent_taskt7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/edit2', data: data, method: 'POST' });
// 评估规则
const http_patent_taskt8:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/edit3', data: data, method: 'POST' });
const http_patent_tasktget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/get_edit', data: data, method: 'POST' });
const http_patent_tasktget6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/get_edit1', data: data, method: 'POST' });
const http_patent_tasktget7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/get_edit2', data: data, method: 'POST' });
const http_patent_tasktget8:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/get_edit3', data: data, method: 'POST' });

const http_patent_tasktscore_rule0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/getScoreList', data: data, method: 'POST' });

const http_patent_tasktexpertList0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/getExperts', data: data, method: 'POST' });

const http_editEvaluate:Api = (data: any): any => httpRequest.request({ url: 'api/v1/patent_task/t/editEvaluate', data: data, method: 'POST' });

export default {
  http_patent_taskt0,
  http_patent_taskt5,
  http_patent_taskt6,
  http_patent_taskt7,
  http_patent_taskt8,
  http_patent_tasktget5,
  http_patent_tasktget6,
  http_patent_tasktget7,
  http_patent_tasktget8,
  http_patent_tasktscore_rule0,
  http_patent_tasktexpertList0,
  http_editEvaluate
}
