import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取赋权审批列表
 * @param data
 * @returns
 */
const http_empower_audittableData0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/search', data: data, method: 'POST' });

/**
 * 获取详情
 * @param data
 * @returns
 */
const http_empower_audittableDataget4: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/get_detail', data: data, method: 'POST' });

/**
 * 审批
 * @param data
 * @returns
 */
const http_empower_audittableData5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/edit', data: data, method: 'POST' });

/**
 * 获取审批弹窗数据
 * @param data
 * @returns
 */
const http_empower_audittableDataget5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/get_edit', data: data, method: 'POST' });

/**
 * 申请异常处理
 * @param data
 * @returns
 */
const http_empower_audittableData6: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/edit1', data: data, method: 'POST' });

/**
 * 获取申请异常处理弹窗数据
 * @param data
 * @returns
 */
const http_empower_audittableDataget6: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/get_edit1', data: data, method: 'POST' });

/**
 * 异常复审
 * @param data
 * @returns
 */
const http_empower_audittableData7: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/edit2', data: data, method: 'POST' });

/**
 * 获取异常复审弹窗数据
 * @param data
 * @returns
 */
const http_empower_audittableDataget7: Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_audit/tableData/get_edit2', data: data, method: 'POST' });

export default {
  http_empower_audittableData0,
  http_empower_audittableDataget4,
  http_empower_audittableData5,
  http_empower_audittableDataget5,
  http_empower_audittableData6,
  http_empower_audittableDataget6,
  http_empower_audittableData7,
  http_empower_audittableDataget7
}
