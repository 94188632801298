import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取行业类别列表
 * @param data
 * @returns
 */
const http_industryTableA0: Api = (data: any) => httpRequest.request({ url: 'api/v1/industry/TableA/search', data: data, method: 'POST' });

/**
 * 添加行业类别
 * @param data
 * @returns
 */
const http_industryTableA1: Api = (data: any) => httpRequest.request({ url: 'api/v1/industry/TableA/add', data: data, method: 'POST' });

/**
 * 删除行业类别
 * @param data
 * @returns
 */
const http_industryTableA2: Api = (data: any) => httpRequest.request({ url: 'api/v1/industry/TableA/del', data: data, method: 'POST' });

/**
 * 编辑行业类别
 * @param data
 * @returns
 */
const http_industryTableA5: Api = (data: any) => httpRequest.request({ url: 'api/v1/industry/TableA/edit', data: data, method: 'POST' });

/**
 * 获取编辑行业类别弹窗数据
 * @param data
 * @returns
 */
const http_industryTableAget5: Api = (data: any) => httpRequest.request({ url: 'api/v1/industry/TableA/get_edit', data: data, method: 'POST' });

/**
 * 获取行业类别列表
 * @param data
 * @returns
 */
const http_industryTableAindustries0: Api = (data: any) => httpRequest.request({ url: 'api/v1/industry/TableA/get_industry', data: data, method: 'POST' });

export default {
  http_industryTableA0,
  http_industryTableA1,
  http_industryTableA2,
  http_industryTableA5,
  http_industryTableAget5,
  http_industryTableAindustries0
}
