
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import Utils from '@/utils'

@Options({
  components: {},
  props: {
    content: '',
    placement: {
      type: String,
      default: 'right'
    }
  },
  emits: ['ok']
})
export default class CustomPopover extends SuperVue {
  content!:string
  placement!:string
  visible:boolean=false
  ok1() {
    this.$emit('ok');
    this.visible = false;
  }
  cancel() {
    this.visible = false
  }
}
