import { httpRequest } from '@/plugins'
import '@/api/interface'
import dayjs from 'dayjs'
const address = require('@/plugins/provinces.json')

/**
 * 获取用户列表
 * @param data
 * @returns
 */
const http_userusers0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/user/users/search', data: data, method: 'POST' });

/**
 * 添加用户
 * @param data
 * @returns
 */
const http_userusers1: Api = (data: any) => httpRequest.request({ url: 'api/v1/user/users/add', data: data, method: 'POST' })

/**
 * 获取详情数据
 * @param data
 * @returns
 */
const http_userusersget4: Api = (data: any) => httpRequest.request({ url: 'api/v1/user/users/get_detail', data: data, method: 'POST' });

/**
 * 编辑用户
 * @param data
 * @returns
 */
const http_userusers5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/user/users/edit', data: data, method: 'POST' });

/**
 * 获取编辑用户弹窗数据
 * @param data
 * @returns
 */
const http_userusersget5: Api = (data: any) => httpRequest.request({ url: 'api/v1/user/users/get_edit', data: data, method: 'POST' });

/**
 * 删除用户
 * @param data
 * @returns
 */
const http_userusers2: Api = (data: any): any => httpRequest.request({ url: 'api/v1/user/users/del', data: data, method: 'POST' });

/**
 * 获取部门列表
 * @param data
 * @returns
 */
const http_userusersdepts0: Api = (data: any) => httpRequest.request({ url: 'api/v1/depts/getDeptsList', data: data, method: 'POST' })

/**
 * 获取角色列表 复用角色管理的获取列表接口
 * @param data
 * @returns
 */
const http_userusersroleList0: Api = (data: any) => httpRequest.request({ url: 'api/v1/role/table1/search', data: data, method: 'POST' })

/**
 * 获取行业列表
 * @param data
 * @returns
 */
const http_userusersindustries0: Api = (data: any) => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' })

/**
 * 获取行业列表
 * @param data
 * @returns
 */
const http_userusersindustriyList0: Api = (data: any) => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' })

/**
 * 获取地区数据
 * @param data
 * @returns
 */
const http_userusersaddressData0: Api = (data: any) => new Promise((resolve, reject) => {
  resolve({
    code: '200', message: 'success', data: {
      total: 0,
      data: address.data
    }
  })
})

export default {
  http_userusers0,
  http_userusers1,
  http_userusersget4,
  http_userusers5,
  http_userusersget5,
  http_userusers2,
  http_userusersdepts0,
  http_userusersroleList0,
  http_userusersindustries0,
  http_userusersindustriyList0,
  http_userusersaddressData0
}
