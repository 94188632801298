import { httpRequest } from '@/plugins'
import '@/api/interface'

// 修改密码
const http_changePass: Api = (data: any): any => httpRequest.request({ url: 'api/v1/user/changePass', data: data, method: 'POST' });
// 通过手机号或邮箱修改密码
const http_updatePassByCode: Api = (data: any): any => httpRequest.request({ url: 'api/v1/user/updatePassByCode', data: data, method: 'POST' });
// 发送手机验证码
const http_sendCode: Api = (data: any): any => httpRequest.request({ url: 'api/v1/common/sendCode', data: data, method: 'POST' });
// 发送邮箱验证码
const http_sendEmailCode: Api = (data: any): any => httpRequest.request({ url: 'api/v1/mail/sendEmailCode', data: data, method: 'POST' });

export default {
  http_changePass,
  http_updatePassByCode,
  http_sendCode,
  http_sendEmailCode
}
