import axios, { AxiosInstance } from 'axios'
import { ElMessage } from 'element-plus'
import Config from '@/config'
import store from '@/store'

export class Axios {
  public instance: AxiosInstance

  constructor() {
    // 创建axios实例
    this.instance = axios.create({
      timeout: 1000 * 12,
      // 请求基础路径
      baseURL: process.env.NODE_ENV === 'development' ? Config.baseUrl.dev : Config.baseUrl.pro,
      withCredentials: true
    })
    // 初始化拦截器
    this.initInterceptors()
  }

  // 为了让http.ts中获取初始化好的axios实例
  public getAxios(): AxiosInstance {
    return this.instance
  }

  // 初始化拦截器
  public initInterceptors(): any {
    this.instance.interceptors.request.use(
      (config: any) => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        if (store.state.token) {
          config.headers['token'] = store.state.token
        }
        return config
      },
      (error: any) => {
        return Promise.reject(error)
      })

    // 响应拦截器
    this.instance.interceptors.response.use(
      // 请求成功
      (res: any) => {
        if (res.status === 200) {
          return Promise.resolve(res.data)
        } else {
          this.errorHandle(res)
          return Promise.reject(res.data)
        }
      },
      // 请求失败
      (error: any) => {
        const { response } = error
        if (response) {
          // 请求已发出，但是不在2xx的范围
          this.errorHandle(response)
          return Promise.reject(response.data)
        } else {
          // 处理断网的情况
          // eg:请求超时或断网时，更新state的network状态
          // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
          // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
          ElMessage.warning('网络连接异常,请稍后再试!')
        }
      })
  }

  /**
  * http握手错误
  * @param res 响应回调,根据不同响应进行不同操作
  */
  private errorHandle(res: any) {
    // 状态码判断
    switch (res.status) {
      case 401:
        break
      case 403:
        break
      case 404:
        ElMessage.error('请求的资源不存在')
        break
      default:
        // if (process.env.NODE_ENV === 'development') ElMessage.error('服务器连接错误')
        ElMessage.error('服务器异常')
    }
  }
}
