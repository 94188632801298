import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_evaluate_assignmentt0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/search', data: data, method: 'POST' });
const http_evaluate_assignmentt5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/edit', data: data, method: 'POST' });
const http_evaluate_assignmentt6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/edit1', data: data, method: 'POST' });
const http_evaluate_assignmentt7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/edit2', data: data, method: 'POST' });
const http_evaluate_assignmentt8:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/edit3', data: data, method: 'POST' });
const http_evaluate_assignmentt9:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/edit4', data: data, method: 'POST' });
const http_evaluate_assignmenttget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/get_detail', data: data, method: 'POST' });
const http_evaluate_assignmenttget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/get_edit', data: data, method: 'POST' });
const http_evaluate_assignmenttget6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/get_edit1', data: data, method: 'POST' });
const http_evaluate_assignmenttget7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/get_edit2', data: data, method: 'POST' });
const http_evaluate_assignmenttget8:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/get_edit3', data: data, method: 'POST' });
const http_evaluate_assignmenttget9:Api = (data: any): any => httpRequest.request({ url: 'api/v1/evaluate_assignment/t/get_edit4', data: data, method: 'POST' });

export default {
  http_evaluate_assignmentt0,
  http_evaluate_assignmentt5,
  http_evaluate_assignmentt6,
  http_evaluate_assignmentt7,
  http_evaluate_assignmentt8,
  http_evaluate_assignmentt9,
  http_evaluate_assignmenttget4,
  http_evaluate_assignmenttget5,
  http_evaluate_assignmenttget6,
  http_evaluate_assignmenttget7,
  http_evaluate_assignmenttget8,
  http_evaluate_assignmenttget9
}
