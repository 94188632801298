import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tree-plugin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tree, {
      ref: "tree",
      onCheckChange: _ctx.checkChange,
      data: _ctx.treeData,
      props: _ctx.defaultProps,
      "show-checkbox": "",
      "node-key": "id",
      "default-expand-all": false,
      "expand-on-click-node": false
    }, null, 8, ["onCheckChange", "data", "props"])
  ]))
}