import { httpRequest } from '@/plugins'
import '@/api/interface'
const address = require('@/plugins/provinces.json')

/**
 * 获取我的专利列表
 * @param data
 * @returns
 */
const http_my_patentt0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/search', data: data, method: 'POST' });

/**
 * 添加专利
 * @param data
 * @returns
 */
const http_my_patentt1:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/add', data: data, method: 'POST' });
const http_my_patentt2:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/del', data: data, method: 'POST' });
const http_my_patentt5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/edit', data: data, method: 'POST' });
const http_my_patentt6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/edit1', data: data, method: 'POST' });
const http_my_patenttget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/get_detail', data: data, method: 'POST' });
const http_my_patenttget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/get_edit', data: data, method: 'POST' });
const http_my_patenttget6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/get_edit1', data: data, method: 'POST' });

// 获取地区数据
const http_my_patenttgetAddress0: Api = (data: any) => new Promise((resolve, reject) => { resolve({ code: '200', message: 'success', data: { total: 0, data: address.data } }) })

// 获取行业类别树级结构
const http_my_patenttgetIndustry0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' });
// 获取行业类别树级结构
const http_my_patenttgetIndustry20: Api = (data: any): any => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' });

// todo
const http_my_patenttWeightList0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/getWeightList', data: data, method: 'POST' });

export default {
  http_my_patentt0,
  http_my_patentt1,
  http_my_patentt2,
  http_my_patentt5,
  http_my_patentt6,
  http_my_patenttget4,
  http_my_patenttget5,
  http_my_patenttget6,
  http_my_patenttgetAddress0,
  http_my_patenttgetIndustry0,
  http_my_patenttgetIndustry20,
  http_my_patenttWeightList0
}
