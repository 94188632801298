
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'

@Options({
  components: {
    PagePlugin
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return []
      }
    },
    treeData: {
      type: Array,
      default() {
        return []
      }
    }
  }
})
export default class TreePlugin extends SuperVue {
  // data
  treeData!: any[]
  modelValue!: any[]
  defaultProps = {
    children: 'children',
    label: 'label'
  }
  // method
  checkChange() {
    const checkedKeys = this.$refs.tree.getCheckedKeys()
    const halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()
    this.$emit('update:modelValue', checkedKeys.concat(halfCheckedKeys))
  }
  // computed

  mounted() {
    // console.log('modelValue', this.modelValue)
    this.modelValue.forEach((item) => {
      const node = this.$refs.tree.getNode(item)
      if (node?.isLeaf) {
        this.$refs.tree.setChecked(item, true)
      }
    })
  }
  updated() {
    this.$refs.tree.setCheckedKeys([])
    this.modelValue.forEach((item) => {
      const node = this.$refs.tree.getNode(item)
      if (node.isLeaf) {
        this.$refs.tree.setChecked(item, true)
      }
    })
  }
}
