import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取成果列表
 * @param data
 * @returns
 */
const http_outcome_applicationtableA0: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/search', data: data, method: 'POST' });

/**
 * 添加成果
 * @param data
 * @returns
 */
const http_outcome_applicationtableA1: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/add', data: data, method: 'POST' });

/**
 * 删除成果
 * @param data
 * @returns
 */
const http_outcome_applicationtableA2: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/del', data: data, method: 'POST' });

/**
 * 获取成果详情数据
 * @param data
 * @returns
 */
const http_outcome_applicationtableAget4: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/get_detail', data: data, method: 'POST' });

/**
 * 编辑成果
 * @param data
 * @returns
 */
const http_outcome_applicationtableA5: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/edit', data: data, method: 'POST' });

/**
 * 获取编辑成果弹窗数据
 * @param data
 * @returns
 */
const http_outcome_applicationtableAget5: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/get_edit', data: data, method: 'POST' });

/**
 * 成果自评
 * @param data
 * @returns
 */
const http_outcome_applicationtableA6: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/edit1', data: data, method: 'POST' });

/**
 * 获取成果自评弹窗数据
 * @param data
 * @returns
 */
const http_outcome_applicationtableAget6: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/get_edit1', data: data, method: 'POST' });

/**
 * 提交成果
 * @param data
 * @returns
 */
const http_outcome_applicationtableA7: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/edit2', data: data, method: 'POST' });

/**
 * 获取提交成果弹窗数据
 * @param data
 * @returns
 */
const http_outcome_applicationtableAget7: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/get_edit2', data: data, method: 'POST' });

/**
 * 申诉
 * @param data
 * @returns
 */
const http_outcome_applicationtableA8: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/edit3', data: data, method: 'POST' });

/**
 * 获取申诉弹窗数据
 * @param data
 * @returns
 */
const http_outcome_applicationtableAget8: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/get_edit3', data: data, method: 'POST' });

/**
 * (自邀专家评)邀请专家
 * @param data
 * @returns
 */
const http_outcome_applicationtableA9: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/edit4', data: data, method: 'POST' });

/**
 * 获取邀请专家弹窗数据
 * @param data
 * @returns
 */
const http_outcome_applicationtableAget9: Api = (data: any) => httpRequest.request({ url: 'api/v1/outcome_application/tableA/get_edit4', data: data, method: 'POST' });

/**
 *
 * @param data
 * @returns
 */
const http_outcome_applicationtableAresMenus0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_application/tableA/get_res_menu', data: data, method: 'POST' });

/**
 *
 * @param data
 * @returns
 */
const http_outcome_applicationtableAweights0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/getWeightList', data: data, method: 'POST' });

const http_outcome_applicationtableAweights20: Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/getWeightList', data: data, method: 'POST' });

const http_outcome_applicationtableAindustry0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' })

const http_outcome_applicationtableAindustries0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' })

const http_outcome_applicationtableAuserList0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/outcome_application/user_list', data: data, method: 'POST' })

export default {
  http_outcome_applicationtableA0,
  http_outcome_applicationtableA1,
  http_outcome_applicationtableA2,
  http_outcome_applicationtableAget4,
  http_outcome_applicationtableA5,
  http_outcome_applicationtableAget5,
  http_outcome_applicationtableA6,
  http_outcome_applicationtableAget6,
  http_outcome_applicationtableA7,
  http_outcome_applicationtableAget7,
  http_outcome_applicationtableA8,
  http_outcome_applicationtableAget8,
  http_outcome_applicationtableA9,
  http_outcome_applicationtableAget9,
  http_outcome_applicationtableAresMenus0,
  http_outcome_applicationtableAweights0,
  http_outcome_applicationtableAweights20,
  http_outcome_applicationtableAindustry0,
  http_outcome_applicationtableAindustries0,
  http_outcome_applicationtableAuserList0
}
