import { httpRequest } from '@/plugins'
import '@/api/interface'

interface UserLogin {
  phone: string,
  password: string
}

/**
 * 用户登录
 * @param data
 * @returns
 */
const http_userLogin: Api = (data: UserLogin): any => httpRequest.request({ url: 'api/v1/user/login', data: data, method: 'POST' })

const http_loginSMS: Api = (data: UserLogin): any => httpRequest.request({ url: 'api/v1/user/loginSMS', data: data, method: 'POST' })

// 获取用户权限
const htttp_getAccess: Api = (data: any) => httpRequest.request({ url: 'api/v1/user/getAccess', data: data, method: 'POST' })

// 退出登录
const http_outputLogin: Api = (data: any) => httpRequest.request({ url: 'api/v1/user/outputLogin', data, method: 'POST' })

export default {
  http_userLogin,
  http_loginSMS,
  htttp_getAccess,
  http_outputLogin
}

