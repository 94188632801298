import { httpRequest } from '@/plugins'
import '@/api/interface'
// 个人中心

const http_personal_center_getUser:Api = (data: any): any => httpRequest.request({ url: 'api/v1/personal_center/getUser', data: data, method: 'POST' });
const http_personal_center_getBankList:Api = (data: any): any => httpRequest.request({ url: 'api/v1/personal_center/getBankList', data: data, method: 'POST' });
const http_personal_center_editUser:Api = (data: any): any => httpRequest.request({ url: 'api/v1/personal_center/editUser', data: data, method: 'POST' });
const http_personal_center_editBank:Api = (data: any): any => httpRequest.request({ url: 'api/v1/personal_center/editBank', data: data, method: 'POST' });
const http_personal_center_delBank:Api = (data: any): any => httpRequest.request({ url: 'api/v1/personal_center/delBank', data: data, method: 'POST' });
const http_personal_center_addBank:Api = (data: any): any => httpRequest.request({ url: 'api/v1/personal_center/addBank', data: data, method: 'POST' });

const http_personal_center_edit:Api = (data:any):any => httpRequest.request({ url: 'api/v1/personal_center/f/edit', data: data, method: 'POST' })

export default {
  http_personal_center_getUser,
  http_personal_center_editUser,
  http_personal_center_editBank,
  http_personal_center_delBank,
  http_personal_center_addBank,
  http_personal_center_getBankList,
  http_personal_center_edit
}

// api/v1/personal_center/editBank 修改银行卡信息
// api/v1/personal_center/delBank 修改银行卡信息
// api/v1/personal_center/addBank 修改银行卡信息
// api/v1/personal_center/editUser 修改密码或性别,非必传,密码记得加密

