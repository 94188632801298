import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_certification_audittable10:Api = (data: any): any => httpRequest.request({ url: 'api/v1/certification_audit/table1/search', data: data, method: 'POST' });

const http_certification_audittable15:Api = (data: any): any => httpRequest.request({ url: 'api/v1/certification_audit/table1/edit', data: data, method: 'POST' });
const http_certification_audittable1get5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/certification_audit/table1/get_edit', data: data, method: 'POST' });

export default {
  http_certification_audittable10,
  http_certification_audittable15,
  http_certification_audittable1get5
}
