
import { SuperVue } from '@/mixins/SuperVue'
import { Options } from 'vue-class-component'
import Config from '@/config'
import store from '@/store'
interface Ifl {
  name: string
  url: string
}
@Options({
  components: {},
  props: {
    modelValue: {
      type: Array,
      require: true,
      default() {
        return []
      }
    },
    title: {
      type: String,
      default: '上传'
    }
  }
})
export default class UploadFile extends SuperVue {
  title!: string
  // token
  get token() {
    if (!store.state.token) return 'noToken'
    return store.state.token
  }
  // 上传路径
  get uploadUrl() {
    return process.env.NODE_ENV === 'development'
      ? Config.baseUrl.dev
      : Config.baseUrl.pro
  }
  modelValue!: Ifl[]
  uploadSuccess(response, file, fileList) {
    if (response.code !== '500') {
      this.modelValue.push({ url: response.data, name: file.name })
      console.log(this.modelValue)
      this.$emit('update:modelValue', this.modelValue)
      this.$emit('change')
      this.$forceUpdate()
      this.$message.success('上传成功')
    } else {
      this.$message.error(response.message)
    }
  }
  del(index) {
    this.modelValue.splice(index, 1)
    this.$emit('update:modelValue', this.modelValue)
    this.$emit('change')
    this.$forceUpdate()
  }
}
