import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取赋权列表
export const http_empower_manageempowers0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_manage/empowers/search', data: data, method: 'POST' });
// 获取赋权详情信息
export const http_empower_manageempowersget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_manage/empowers/get_detail', data: data, method: 'POST' });
// 指派审批人
export const http_empower_manageempowers5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_manage/empowers/edit', data: data, method: 'POST' });
// 获取指派弹窗数据
export const http_empower_manageempowersget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/empower_manage/empowers/get_edit', data: data, method: 'POST' });
// 获取需指派人员列表
export const http_empower_manageempowersuserList0:Api = data => httpRequest.request({ url: 'api/v1/empower_manage/empowers/get_edit_users', data: data, method: 'POST' })

// 获取下一成果状态的审批部门
export const http_empower_manageEmpowerGetDepts:Api = data => httpRequest.request({ url: 'api/v1/empower_manage/empowers/get_edit_depts', data: data, method: 'POST' })
