import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_change_audittableData0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/search', data: data, method: 'POST' });
const http_change_audittableDataget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/get_detail', data: data, method: 'POST' });
const http_change_audittableData5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/edit', data: data, method: 'POST' });
const http_change_audittableDataget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/get_edit', data: data, method: 'POST' });
const http_change_audittableData6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/edit1', data: data, method: 'POST' });
const http_change_audittableDataget6:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/get_edit1', data: data, method: 'POST' });
const http_change_audittableData7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/edit2', data: data, method: 'POST' });
const http_change_audittableDataget7:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_audit/tableData/get_edit2', data: data, method: 'POST' });

export default {
  http_change_audittableData0,
  http_change_audittableDataget4,
  http_change_audittableData5,
  http_change_audittableDataget5,
  http_change_audittableData6,
  http_change_audittableDataget6,
  http_change_audittableData7,
  http_change_audittableDataget7

}
