
import { defineComponent, ref, nextTick } from 'vue'
export default defineComponent({
  provide() {
    return { reload: this.reload }
  },
  setup(props, context) {
    const isRouterActive = ref(true)
    async function reload() {
      isRouterActive.value = false
      await nextTick()
      isRouterActive.value = true
    }
    return { isRouterActive, reload }
  }
})
