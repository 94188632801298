import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_open_invitationtableA0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/open_invitation/tableA/search', data: data, method: 'POST' });

const http_open_invitationtableAget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/open_invitation/tableA/get_detail', data: data, method: 'POST' });
const http_open_invitationtableA5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/open_invitation/tableA/edit', data: data, method: 'POST' });
const http_open_invitationtableAget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/open_invitation/tableA/get_edit', data: data, method: 'POST' });

export default {
  http_open_invitationtableA0,
  http_open_invitationtableAget4,
  http_open_invitationtableA5,
  http_open_invitationtableAget5
}
