import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取权重列表
const http_getWeightList: Api = (data: any) => httpRequest.request({ url: 'api/v1/weight/getWeightList', data: data, method: 'POST' })
// 添加权重
const http_addWeight: Api = (data: any) => httpRequest.request({ url: 'api/v1/weight/addWeight', data: data, method: 'POST' })
// 删除权重
const http_delWeight: Api = (data: any) => httpRequest.request({ url: 'api/v1/weight/delWeight', data: data, method: 'POST' })
// 编辑权重
const http_updateWeight: Api = (data: any) => httpRequest.request({ url: 'api/v1/weight/updateWeight', data: data, method: 'POST' })
// 获取搜索权重类别
const http_getAllType: Api = (data: any): any => {
  data.type = 1
  data.offset = 0
  data.length = 999
  return httpRequest.request({ url: 'api/v1/type/getAll', data: data, method: 'POST' })
}

export default {
  http_getWeightList,
  http_addWeight,
  http_delWeight,
  http_updateWeight,
  http_getAllType
}
