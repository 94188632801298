
import { SuperVue } from '@/mixins/SuperVue'
import { Options } from 'vue-class-component'
@Options({
  components: {},
  props: {
    modelValue: {
      type: Array,
      default() {
        return [-1, -1, [], 0]
      }
    },
    member: {
      type: Array,
      default() {
        return []
      }
    }
  }
})
export default class Login extends SuperVue {
  mounted() {
    this.rule = this.modelValue[0] === -1 ? 0 : this.modelValue[0] // 默认选择平均值
    this.modelValue[0] = this.rule
    this.radio = this.modelValue[1]
    this.checkBox = this.modelValue[2]
    this.input = this.modelValue[3]
  }
  modelValue!: any[]
  member!: any[]
  rule = 0
  radio = 0
  checkBox = []
  input: any = ''
  ruleChange(e: number): void {
    this.modelValue[0] = e;
    if (e === 3) {
      this.cDidsbled = false
    } else {
      this.cDidsbled = true
    }
    if (e === 2) {
      this.cBidsbled = false
    } else {
      this.cBidsbled = true
    }
  }
  radioChange(e: number): void {
    this.modelValue[1] = e
  }
  chickBoxChange(e): void {
    this.modelValue[2] = e
  }
  scoreChange(e) {
    if (e > 10) this.input = 10
    if (e < 0) this.input = 0
    this.modelValue[3] = this.input
  }
  cDidsbled = true
  cBidsbled = true
}

