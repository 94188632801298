import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取需要审核报告列表
 * @param data
 * @returns
 */
const http_report_reviewtableA0: Api = (data: any) => httpRequest.request({ url: 'api/v1/report_review/tableA/search', data: data, method: 'POST' });

/**
 * 获取成果评估详情数据
 * @param data
 * @returns
 */
const http_report_reviewtableAget4: Api = (data: any) => httpRequest.request({ url: 'api/v1/report_review/tableA/get_detail', data: data, method: 'POST' });

/**
 * 审核评估报告
 * @param data
 * @returns
 */
const http_report_reviewtableA5: Api = (data: any) => httpRequest.request({ url: 'api/v1/report_review/tableA/edit', data: data, method: 'POST' });

/**
 * 获取审核评估报告审核弹窗数据
 * @param data
 * @returns
 */
const http_report_reviewtableAget5: Api = (data: any) => httpRequest.request({ url: 'api/v1/report_review/tableA/get_edit', data: data, method: 'POST' });

/**
 * 获取行业类别列表
 * @param data
 * @returns
 */
const http_report_reviewtableAindustry0: Api = (data: any) => new Promise((resolve, reject) => {
  resolve({
    code: '200', message: 'success', data: {
      total: 0,
      data: []
    }
  })
})

/**
 * 获取行业类别列表
 * @param data
 * @returns
 */
const http_report_reviewtableAindustryList0: Api = (data: any) => new Promise((resolve, reject) => {
  resolve({
    code: '200', message: 'message', data: {
      total: 0,
      data: []
    }
  })
})

export default {
  http_report_reviewtableA0,
  http_report_reviewtableAget4,
  http_report_reviewtableA5,
  http_report_reviewtableAget5,
  http_report_reviewtableAindustry0,
  http_report_reviewtableAindustryList0
}
