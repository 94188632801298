import { httpRequest } from '@/plugins'
import '@/api/interface'

const http_change_managechanges0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_manage/changes/search', data: data, method: 'POST' });
const http_change_managechangesget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_manage/changes/get_detail', data: data, method: 'POST' });
const http_change_managechanges5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_manage/changes/edit', data: data, method: 'POST' });
const http_change_managechangesget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/change_manage/changes/get_edit', data: data, method: 'POST' });
const http_change_managechangesuserList0:Api = data => httpRequest.request({ url: 'api/v1/change_manage/changes/get_edit_users', data: data, method: 'POST' })
const http_change_managechangesuserDepts:Api = data => httpRequest.request({ url: 'api/v1/change_manage/changes/get_edit_depts', data: data, method: 'POST' })

export default {
  http_change_managechanges0,
  http_change_managechangesget4,
  http_change_managechanges5,
  http_change_managechangesget5,
  http_change_managechangesuserList0,
  http_change_managechangesuserDepts
}
