import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取成果列表
 * @param data
 * @returns
 */
const http_business_managementtableA0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/search', data: data, method: 'POST' });

/**
 * 获取成果详情
 * @param data
 * @returns
 */
const http_business_managementtableAget4: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/get_detail', data: data, method: 'POST' });

/**
 * 审核评估申请
 * @param data
 * @returns
 */
const http_business_managementtableA5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/edit', data: data, method: 'POST' });

/**
 * 获取审核数据
 * @param data
 * @returns
 */
const http_business_managementtableAget5: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/get_edit', data: data, method: 'POST' });

/**
 * 指派评估组长
 * @param data
 * @returns
 */
const http_business_managementtableA6: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/edit1', data: data, method: 'POST' });

/**
 * 获取指派评估组长弹窗数据
 * @param data
 * @returns
 */
const http_business_managementtableAget6: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/get_edit1', data: data, method: 'POST' });

/**
 * 指派审查员
 * @param data
 * @returns
 */
const http_business_managementtableA7: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/edit2', data: data, method: 'POST' });

/**
 * 获取指派审查弹窗数据
 * @param data
 * @returns
 */
const http_business_managementtableAget7: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/get_edit2', data: data, method: 'POST' });

/**
 * 盖章确认
 * @param data
 * @returns
 */
const http_business_managementtableA8: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/edit3', data: data, method: 'POST' });

/**
 * 获取盖章确认弹窗数据
 * @param data
 * @returns
 */
const http_business_managementtableAget8: Api = (data: any): any => httpRequest.request({ url: 'api/v1/business_management/tableA/get_edit3', data: data, method: 'POST' });

/**
 * 获取评估组长列表
 * @param data
 * @returns
 */
const http_business_managementtableAleaders0: Api = (data: any) => new Promise((resolve, reject) => {
  resolve({ code: '200', message: 'success', data: '' })
})

/**
 * 获取评估审查员列表
 * @param data
 * @returns
 */
const http_business_managementtableAtexamines0: Api = (data: any) => new Promise((resolve, reject) => {
  resolve({ code: '200', message: 'success', data: '' })
})

export default {
  http_business_managementtableA0,
  http_business_managementtableAget4,
  http_business_managementtableA5,
  http_business_managementtableAget5,
  http_business_managementtableA6,
  http_business_managementtableAget6,
  http_business_managementtableA7,
  http_business_managementtableAget7,
  http_business_managementtableA8,
  http_business_managementtableAget8,
  http_business_managementtableAleaders0,
  http_business_managementtableAtexamines0
}
