import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取师生员工一审（院审）列表
const http_firstInstance: Api = (data) => httpRequest.request({ url: 'api/v1/home/firstInstance', data: data, method: 'POST' });

// 获取师生员工二审（校审）列表
const http_secondInstance: Api = (data) => httpRequest.request({ url: 'api/v1/home/secondInstance', data: data, method: 'POST' });

// 获取评估员审核列表
const http_assessorAudit: Api = (data) => httpRequest.request({ url: 'api/v1/home/assessorAudit', data: data, method: 'POST' });

// 申请统计
// 申请数量
const http_applyStatistics: Api = (data) => httpRequest.request({ url: 'api/v1/home/applyStatistics', data: data, method: 'POST' });

// 申请成功率
const http_applyPass: Api = (data) => httpRequest.request({ url: 'api/v1/home/applyPass', data: data, method: 'POST' });

// 申请趋势图
const http_applyHistogram: Api = (data) => httpRequest.request({ url: 'api/v1/home/applyHistogram', data: data, method: 'POST' });

// 登录信息 /api/v1/home/loginInfo
const http_loginInfo: Api = (data) => httpRequest.request({ url: 'api/v1/home/loginInfo', data: data, method: 'POST' });

// 师生员工工作小结 /api/v1/home/sumUpWork
const http_sumUpWork: Api = (data) => httpRequest.request({ url: 'api/v1/home/sumUpWork', data: data, method: 'POST' });

// 专家综合评价 /api/v1/home/evaluate
const http_evaluate: Api = (data) => httpRequest.request({ url: 'api/v1/home/evaluate', data: data, method: 'POST' });

// 分数排行榜 /api/v1/home/scoreToptenReviews
const http_scoreToptenReviews: Api = (data) => httpRequest.request({ url: 'api/v1/home/scoreToptenReviews', data: data, method: 'POST' });

// 评价任务情况 /api/v1/home/taskDetails
const http_taskDetails: Api = (data) => httpRequest.request({ url: 'api/v1/home/taskDetails', data: data, method: 'POST' });

// 专家评价趋势图 /api/v1/home/applyHistogramExpert
const http_applyHistogramExpert: Api = (data) => httpRequest.request({ url: 'api/v1/home/applyHistogramExpert', data: data, method: 'POST' });

// 管理员工作小结 api/v1/home/sumUpWorkAdmin
const http_sumUpWorkAdmin: Api = (data) => httpRequest.request({ url: 'api/v1/home/sumUpWorkAdmin', data: data, method: 'POST' });

// 专利申请前评估
const http_backlogPatents: Api = (data) => httpRequest.request({ url: 'api/v1/home/backlogPatents', data: data, method: 'POST' });

// 成果转化申请
const http_backlogResChanges: Api = (data) => httpRequest.request({ url: 'api/v1/home/backlogResChanges', data: data, method: 'POST' });

// 成果赋权申请
const http_backlogResEmpowers: Api = (data) => httpRequest.request({ url: 'api/v1/home/backlogResEmpowers', data: data, method: 'POST' });

// 评价平均分
const http_patentsArgScore: Api = (data) => httpRequest.request({ url: 'api/v1/home/patentsArgScore', data: data, method: 'POST' });

// 院系统计top10
const http_collegeTOP: Api = (data) => httpRequest.request({ url: 'api/v1/home/collegeTOP', data: data, method: 'POST' });

export default {
  http_firstInstance,
  http_secondInstance,
  http_assessorAudit,
  http_applyStatistics,
  http_applyPass,
  http_applyHistogram,
  http_loginInfo,
  http_sumUpWork,
  http_evaluate,
  http_scoreToptenReviews,
  http_taskDetails,
  http_applyHistogramExpert,
  http_sumUpWorkAdmin,
  http_backlogPatents,
  http_backlogResChanges,
  http_backlogResEmpowers,
  http_patentsArgScore,
  http_collegeTOP
}
