
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import { Options } from 'vue-class-component'
@Options({
  components: {},
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    options(newValue) {
      this.optionsAnayse()
    }
  }
})
export default class Login extends SuperVue {
  tva:string[][]= []
  created() {
    this.optionsAnayse()
  }
  options!:any[]
  optionsAnayse() {
    this.tva = [
      ['第10级', '销售级/应用级', '', '', '', '', '', '', '', '', '', ''],
      ['第9级', '系统级', '', '', '', '', '', '', '', '', '', ''],
      ['第8级', '产品级', '', '', '', '', '', '', '', '', '', ''],
      ['第7级', '环境级', '', '', '', '', '', '', '', '', '', ''],
      ['第6级', '正样级', '', '', '', '', '', '', '', '', '', ''],
      ['第5级', '初样级', '', '', '', '', '', '', '', '', '', ''],
      ['第4级', '仿真级', '', '', '', '', '', '', '', '', '', ''],
      ['第3级', '功能级', '', '', '', '', '', '', '', '', '', ''],
      ['第2级', '方案级', '', '', '', '', '', '', '', '', '', ''],
      ['第1级', '报告级', '', '', '', '', '', '', '', '', '', ''],
      ['第0级', '无', '', '', '', '', '', '', '', '', '', '']
    ]
    let sortOption = JSON.parse(JSON.stringify(this.options))
    sortOption = sortOption.sort((a, b) => a.sort - b.sort);
    sortOption.forEach((item, index0:number) => {
      const temp = item.children.sort((a, b) => a.sort - b.sort);
      temp.forEach((item1, index1:number) => {
        this.tva[10 - item1.before_maturity][item1.result_form * 2 ] += `${ index0 + 1 }.${ index1 + 1 };`
        this.tva[10 - item1.after_maturity][item1.result_form * 2 + 1] += `${ index0 + 1 }.${ index1 + 1 };`
      });
    })
  }
}
