import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取/搜索行业类别
const http_getCategoryList: Api = (data: any): any => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' })
const http_queryDeptsList: Api = (data: any): any => httpRequest.request({ url: 'api/v1/dept/depts/search', data: data, method: 'POST' });
const http_userRegister: Api = (data: any): any => httpRequest.request({ url: 'api/v1/user/users/register', data: data, method: 'POST' });

export default {
  http_getCategoryList,
  http_queryDeptsList,
  http_userRegister
}
