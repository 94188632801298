
import { SuperVue } from '@/mixins/SuperVue'
import { Options } from 'vue-class-component'

@Options({
  components: {},
  props: {
    detail: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    detail() {

    }
  }
})
export default class WeightDetail extends SuperVue {
  detail!:any[]
}
