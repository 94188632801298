import testApi from './pageApi/testApi'
import baseV1 from './pageApi/base.v1'
import industryManageV1 from './pageApi/industry-manage.v1'
import quotaV1 from './pageApi/quota.v1'
import registerV1 from './pageApi/register.v1'
import userManageV1 from './pageApi/user-manage.v1'
import roleManageV1 from './pageApi/role-manage.v1'
import complaintsHandlingV1 from './pageApi/complaints-handling.v1'
import applyHistoryV1 from './pageApi/apply-history.v1'
import certificationAuditV1 from './pageApi/certification-audit.v1'
import deptManageV1 from './pageApi/dept-manage.v1'
import loginV1 from './pageApi/login.v1'
import myPatentV1 from './pageApi/my-patent.v1'
import paisV1 from './pageApi/pais.v1'
import applyAuditV1 from './pageApi/apply-audit.v1'
import patentTaskV1 from './pageApi/patent-task.v1'
import applyConfirmV1 from './pageApi/apply-confirm.v1'
import patentManageV1 from './pageApi/patent-manage.v1'
import applyTaskV1 from './pageApi/apply-task.v1'
import assessorAuditV1 from './pageApi/assessor-audit.v1'
import evaluateAssignmentV1 from './pageApi/evaluate-assignment.v1'
import outcomeChangeV1 from './pageApi/outcome-change.v1'
import proofreadingV1 from './pageApi/proofreading.v1'
import resultEmpowerV1 from './pageApi/result-empower.v1'
import supplementaryAuditV1 from './pageApi/supplementary-audit.v1'
import supplementaryMaterialsV1 from './pageApi/supplementary-materials.v1'
import businessManageV1 from './pageApi/business-manage.v1'
import evaluateTaskV1 from './pageApi/evaluate-task.v1'
import openInvitationV1 from './pageApi/open-invitation.v1'
import reportReviewV1 from './pageApi/report-review.v1'
import outcomeApplicationV1 from './pageApi/outcome-application.v1'
import personV1 from './pageApi/person.v1'
import changeAuditV1 from './pageApi/change-audit.v1'
import empowerAuditV1 from './pageApi/empower-audit.v1'
import * as empoverManageV1 from './pageApi/empower-manage.v1'
import changeManageV1 from './pageApi/change-manage.v1'
import forget_passV1 from './pageApi/forget_pass.v1'
import messageCenterV1 from './pageApi/message-center.v1'
import workbenchV1 from './pageApi/workbench.v1'

export default {
  ...testApi,
  ...baseV1,
  ...registerV1,
  ...industryManageV1,
  ...userManageV1,
  ...roleManageV1,
  ...complaintsHandlingV1,
  ...applyHistoryV1,
  ...certificationAuditV1,
  ...deptManageV1,
  ...applyHistoryV1,
  ...myPatentV1,
  ...paisV1,
  ...myPatentV1,
  ...quotaV1,
  ...loginV1,
  ...applyAuditV1,
  ...patentTaskV1,
  ...loginV1,
  ...applyConfirmV1,
  ...patentManageV1,
  ...applyTaskV1,
  ...assessorAuditV1,
  ...evaluateAssignmentV1,
  ...outcomeChangeV1,
  ...proofreadingV1,
  ...resultEmpowerV1,
  ...supplementaryAuditV1,
  ...supplementaryMaterialsV1,
  ...applyConfirmV1,
  ...businessManageV1,
  ...evaluateTaskV1,
  ...openInvitationV1,
  ...reportReviewV1,
  ...outcomeApplicationV1,
  ...personV1,
  ...changeAuditV1,
  ...empowerAuditV1,
  ...empoverManageV1,
  ...changeManageV1,
  ...forget_passV1,
  ...messageCenterV1,
  ...workbenchV1
}
