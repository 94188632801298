import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取/搜索师生员工表格
const http_paistable0:Api = (data: any): any => httpRequest.request({ url: 'api/v1/pais/table/search', data: data, method: 'POST' });
// 审核
const http_paistable5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/pais/table/edit', data: data, method: 'POST' });
const http_paistableget4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/pais/table/get_detail', data: data, method: 'POST' });
const http_paistableget5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/pais/table/get_edit', data: data, method: 'POST' });

export default {
  http_paistable0,
  http_paistable5,
  http_paistableget4,
  http_paistableget5
}
