import { httpRequest } from '@/plugins'
import '@/api/interface'

// 获取确认表格数据
const http_apply_confirmtabel10:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_confirm/tabel1/search', data: data, method: 'POST' });
// 获取详情
const http_apply_confirmtabel1get4:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_confirm/tabel1/get_detail', data: data, method: 'POST' });
// 审核
const http_apply_confirmtabel15:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_confirm/tabel1/edit', data: data, method: 'POST' });
// 获取审核弹窗数据
const http_apply_confirmtabel1get5:Api = (data: any): any => httpRequest.request({ url: 'api/v1/apply_confirm/tabel1/get_edit', data: data, method: 'POST' });

// 获取行业类别树级结构
const http_apply_confirmtabel1getIndustry0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' });
const http_apply_confirmtabel1getIndustry20: Api = (data: any): any => httpRequest.request({ url: 'api/v1/category/getCategories', data: data, method: 'POST' });
// 获取权重列表,复用my_patent
const http_apply_confirmtabel1WeightList0: Api = (data: any): any => httpRequest.request({ url: 'api/v1/my_patent/t/getWeightList', data: data, method: 'POST' });

export default {
  http_apply_confirmtabel10,
  http_apply_confirmtabel1get4,
  http_apply_confirmtabel15,
  http_apply_confirmtabel1get5,
  http_apply_confirmtabel1getIndustry0,
  http_apply_confirmtabel1getIndustry20,
  http_apply_confirmtabel1WeightList0
}
