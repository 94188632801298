import { Options, Vue } from 'vue-class-component'
import store from '@/store'

Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({
  components: {},
  directives: {
    preventReClick: {
      mounted(el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 3000)
          }
        })
      }
    }
  }
})

export class SuperVue extends Vue {
  removeDialogName() {
    store.commit('removeDialogName')
  }
  setDialogName(name:string) {
    store.commit('setDialogName', name)
  }
  $message!: {
    info: any
    success: any
    warning: any
    error: any
  }
  $store!:any
  $confirm!: any
  $set!:any
  $emit!:any
  $refs!:{
    [p:string]:any
  }
  public $url!:any

}
