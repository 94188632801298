import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import Config from './config'

// 引入中文语言，解决element-plus分页组件出现英文问题
import locale from 'element-plus/lib/locale/lang/zh-cn' // element-plus
import 'element-plus/dist/index.css'
import '@/plugins/element-plus.less'
import '@/plugins/global.less'
// 组件注册
import treePlugin from '@/components/tree-plugin/tree-plugin.vue' // 权限树组件
import uploadFile from '@/components/upload-file/upload-file.vue' // 上传组件
import evaluateSelf from '@/components/evaluate-self/evaluate-self.vue' // 指标评分组件
import appointedExpert from '@/components/appointed-expert/appointed-expert.vue' // 指派专家组件
import scoreRule from '@/components/score-rule/score-rule.vue' // 评分规则组件
import weightDetail from '@/components/weight-detail/weight-detail.vue' // 指标评分详情组件
import DeptUserTree from '@/components/dept-user-tree/dept-user-tree.vue' // 部门/用户树组件,业务组件
import CustomPopover from '@/components/custom-popover/custom-popover.vue' // 自定义二次确认窗
import bmk from '@/components/custom-table/bmk.vue'
import wbs from '@/components/custom-table/wbs.vue'
import tva from '@/components/custom-table/tva.vue'
import qcd from '@/components/custom-table/qcd.vue'
const app = createApp(App)

// 全局变量
app.config.globalProperties.$url = Config.IMG_URL

// 插件
app.use(store)
app.use(router)
app.use(ElementPlus, { locale })
// 全局组件
app.component('treePlugin', treePlugin)
app.component('uploadFile', uploadFile)
app.component('evaluateSelf', evaluateSelf)
app.component('appointedExpert', appointedExpert)
app.component('scoreRule', scoreRule)
app.component('weightDetail', weightDetail)
app.component('deptUserTree', DeptUserTree)
app.component('bmk', bmk)
app.component('wbs', wbs)
app.component('tva', tva)
app.component('qcd', qcd)
app.component('CustomPopover', CustomPopover)

// 图标
import { Warning, Search, ArrowLeft, ArrowRight, DCaret, Delete, Edit, CreditCard, CaretRight, CircleCloseFilled, CircleCheckFilled, Female, Male, Camera, Select, SemiSelect, StarFilled, Back, Iphone, Lock, CaretBottom, CaretTop } from '@element-plus/icons'
app.component('warning', Warning).component('delete', Delete)
app.component('DCaret', DCaret)
app.component('ArrowLeft', ArrowLeft)
app.component('ArrowRight', ArrowRight)
app.component('edit', Edit)
app.component('CreditCard', CreditCard)
app.component('CaretRight', CaretRight)
app.component('CircleCloseFilled', CircleCloseFilled).component('CircleCheckFilled', CircleCheckFilled)
app.component('male', Male).component('female', Female)
app.component('Camera', Camera)
app.component('Select', Select)
app.component('SemiSelect', SemiSelect)
app.component('StarFilled', StarFilled)
app.component('Back', Back)
app.component('Iphone', Iphone)
app.component('Lock', Lock).component('CaretBottom', CaretBottom).component('CaretTop', CaretTop)
app.component('Search', Search)

// app.component("preventReClick",{
//   directives: {
//     preventReClick:{
//       mounted(el,binding) {
//         el.addEventListener('click', () => {
//           if (!el.disabled) {
//               el.disabled = true
//               setTimeout(() => {
//                   el.disabled = false
//               }, binding.value || 3000)
//           }
//       })
//       }
//     }
//   }
// })

// 挂载
app.mount('#app')
