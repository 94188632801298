import { httpRequest } from '@/plugins'
import '@/api/interface'

/**
 * 获取专家评估任务列表
 * @param data
 * @returns
 */
const http_evaluate_tasktableA0: Api = (data: any) => httpRequest.request({ url: 'api/v1/evaluate_task/tableA/search', data: data, method: 'POST' });

/**
 * 专家评估
 * @param data
 * @returns
 */
const http_evaluate_tasktableA5: Api = (data: any) => httpRequest.request({ url: 'api/v1/evaluate_task/tableA/edit', data: data, method: 'POST' });

/**
 * 获取专家评估弹窗数据
 * @param data
 * @returns
 */
const http_evaluate_tasktableAget5: Api = (data: any) => httpRequest.request({ url: 'api/v1/evaluate_task/tableA/get_edit', data: data, method: 'POST' });
// const http_evaluate_tasktableAget5: Api = (data: any) => new Promise((resolve, reject) => {
//   resolve({ code: '200', message: 'success', data: {
//     opinions: [],
//     person_info: {},
//     base_info: {},
//     supplement: {},
//     conversion_situation: {},
//     self_info: []
//   } })
// })

/**
 * 获取权重列表
 * @param data
 * @returns
 */

const http_evaluate_tasktableAweightList0: Api = (data: any) => httpRequest.request({ url: 'api/v1/evaluate_task/tableA/getOpinions', data: data, method: 'POST' });

/**
 * 获取权重列表
 * @param data
 * @returns
 */
const http_evaluate_tasktableAweights0: Api = (data: any) => httpRequest.request({ url: 'api/v1/my_patent/t/getWeightList', data: data, method: 'POST' });

export default {
  http_evaluate_tasktableA0,
  http_evaluate_tasktableA5,
  http_evaluate_tasktableAget5,
  http_evaluate_tasktableAweightList0,
  http_evaluate_tasktableAweights0
}
