
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import { Options } from 'vue-class-component'
@Options({
  components: {},
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    options(newValue) {
      this.optionsAnayse()
    }
  }
})
export default class Login extends SuperVue {
  created() {
    this.optionsAnayse()
  }
  options!:any[]
  table:any[]=[]
  optionsAnayse() {
    this.table = []
    let sortOption = JSON.parse(JSON.stringify(this.options))
    sortOption = sortOption.sort((a, b) => a.sort - b.sort);
    sortOption.forEach((item, index0) => {
      const temp = item.children.sort((a, b) => a.sort - b.sort);
      temp.forEach((item1, index1) => {
        item1.len = temp.length;
        item1.index0 = index0;
        item1.index1 = index1;
        item1.parentName = item.name;
        this.table.push(item1)
      });
    })
  }

}
